<template>
    <!-- ScoreUsersInfoModal -->
    <div class="modal fade" id="ScoreUsersInfoModal" tabindex="-1"
         aria-labelledby="votedUsersLabel"

         aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="votedUsersLabel">Пользователи поставившие оценки</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-for="(user, index) in scoreUsersName" :key="index" >
                        {{user.name}}
                        <div class="mt-1" v-if="user.scoreComment">Комментарий: {{user.scoreComment}}</div>
                        <hr>
                    </div>
                </div>


                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        Закрыть
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- End ScoreUsersInfoModal -->
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: "ScoreUsersInfoModal",
        computed: {
            ...mapState('commission', ['scoreUsersName']),
        }
    }
</script>

<style scoped>

</style>