<template>
    <!-- Score Modal -->
    <div class="modal fade" :id="pageId" tabindex="-1" aria-labelledby="ScoreInfoModalLabel"
         aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="ScoreInfoModalLabel">Оценки</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">

                    <div class="m-2 mt-4">
                        <div v-for="(criterion, index) in criterionData" :key="index" class="row">
                            <div class="col-md-11">
                                {{criterion.name}}:
                            </div>
                            <div class="col-md-1">
                                {{scoreInfo['k'+(index+1)]}}
                            </div>
                            <hr>
                        </div>

                        <div class="row">
                            <div class="col-md-2">
                                Комментарий:
                            </div>
                            <div class="col-md-10">
                                {{scoreInfo['score_comment']}}
                            </div>
                            <hr>
                        </div>

                    </div>

                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        Закрыть
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- End Score Modal -->
</template>
<script>
    import {mapState} from "vuex";

    export default {
        name: 'ScoreInfoModal',
        props: ['pageId'],
        computed: {
            ...mapState('commission', ['criterionData', 'scoreInfo'])
        }
    }

</script>