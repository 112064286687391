<template>
    <div class="container">
        <div v-if="loading" class="d-flex justify-content-center mt-5">
            <Preloader/>
        </div>

        <div class="commission-score-form mt-4" v-else>
            <h4 style="text-align: center">Список кандидатов</h4>


            <ScoreUsersInfoModal/>
            <ScoreModal :id="'ScoreModal'"/>
            <ScoreInfoModal :id="'ScoreInfoModal'"/>
            <DisciplineInfoModal :id="'DisciplineInfoModal'"/>
            <!-- table start -->
            <div class="table-responsive-md mt-4">
                <table class="table table-striped table-bordered text-center">
                    <thead>
                    <tr>
                        <th scope="col">№</th>
                        <th scope="col">ФИО</th>
                        <th scope="col">Название дисциплины</th>
                        <th scope="col">Кафедра</th>
                        <th scope="col">Дата</th>
                        <th scope="col">Резюме</th>
                        <th scope="col">Силлабус</th>
                        <th scope="col">Оценка</th>
                        <th scope="col">Анонимное голосование</th>
                    </tr>
                    </thead>
                    <tbody>

                    <tr v-for="(item,index) in candidateListData" :key="index">
                        <th scope="row">{{ index + 1 }}</th>
                        <td>{{ getFio(item.userSyllabus.user0) }}</td>
                        <td>

                            <button type="button" class="btn btn-link" data-bs-toggle="modal"
                                    data-bs-target="#DisciplineInfoModal" @click="SET_CANDIDATELISTDATAID(item.id)">
                                {{ item.userSyllabus.disciplineVacancy.discipline.name }}
                            </button>


                        </td>
                        <td>{{ item.userSyllabus.disciplineVacancy.discipline.department.name_ru }}</td>
                        <td>
                            <input type="datetime-local" class="form-control"
                                   :value="convertKkkInterviewTimestampToDate(item.date)" disabled>
                        </td>
                        <td>
                            <!--                            <a @click="goToUser(item.userSyllabus.user)" class="link-style">-->
                            <!--                                резюме-->
                            <!--                            </a>-->
                            <router-link to="/user">
                                <span @click="goToUser(item.userSyllabus.user)">резюме</span>
                            </router-link>

                        </td>

                        <td>
                            <a :href="`https://back.uib.kz/syllabus_data/syllabus-print/get-syllabus?user_id=${item?.userSyllabus?.user}&education_discipline_id=${item?.userSyllabus?.disciplineVacancy?.discipline_id}`">силлабус</a>
                        </td>
                        <td>
                            <div v-if="decryptCheckRole('HRHead')">


                                <div v-if="item.score_sum">
                                    <button type="button" class="btn btn-link" data-bs-toggle="modal"
                                            data-bs-target="#ScoreUsersInfoModal"
                                            @click="setScoreUsersId(item.usersId)">{{item.score_sum}}
                                    </button>
                                </div>
                            </div>
                            <div v-else>
                                <div v-if="item.count === false">
                                    <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                            data-bs-target="#ScoreModal" @click="openScoreModal(item.id)">
                                        <i class="fas fa-star-half-alt"></i>
                                    </button>
                                </div>
                                <div v-else>
                                    <button type="button" class="btn btn-link" data-bs-toggle="modal"
                                            data-bs-target="#ScoreInfoModal" @click="openScoreInfoModal(item.id)">
                                        {{item.count}}
                                    </button>
                                </div>

                            </div>

                        </td>

                        <td>
                            <div v-if="decryptCheckRole('HRHead')">
                                <div v-if="item.permission == 1">Проголосовали за {{item.votesum.yes}}, против
                                    {{item.votesum.no}}
                                </div>
                                <div v-else>
                                    <button type="button" class="btn btn-danger" @click="setPermission(item.id)">
                                        дать доступ
                                    </button>
                                </div>
                            </div>
                            <div v-else>
                                <div class="row" v-if="item.vote === null">
                                    <div class="col-md-4">
                                        <button type="button" class="btn btn-success" data-bs-toggle="modal"
                                                data-bs-target="#"
                                                :disabled="item.permission!=1"
                                                @click="setVote(item.id, true)">
                                            За
                                        </button>
                                    </div>
                                    <div class="col-md-8">
                                        <button type="button" class="btn btn-danger" data-bs-toggle="modal"
                                                data-bs-target="#"
                                                :disabled="item.permission!=1"
                                                @click="setVote(item.id, false)">
                                            Против
                                        </button>
                                    </div>

                                </div>
                                <div class="row" v-else>
                                    <div class="col-md-12">
                                        Вы проголосовали
                                    </div>
                                    <!--                                {{item.vote == 1 ? 'за' : 'против'}}-->
                                </div>
                            </div>

                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <!-- table end -->
        </div>
    </div>
</template>


<script>
    import ScoreModal from '@/components/vacancy/ScoreModal.vue'
    import ScoreInfoModal from '@/components/vacancy/ScoreInfoModal.vue'
    import ScoreUsersInfoModal from '@/components/vacancy/ScoreUsersInfoModal.vue'
    import DisciplineInfoModal from '@/components/vacancy/DisciplineInfoModal.vue'
    import {convertKkkInterviewTimestampToDate} from "@/utils/helpers/convertKkkInterviewTimestampToDate"
    import {mapActions, mapMutations, mapState} from "vuex";
    import {decryptCheckRole} from "@/utils/helpers/decryptedRoles.helpers";


    export default {
        name: "CommissionCandidateCriterionScore",
        components: {
            ScoreModal,
            ScoreInfoModal,
            ScoreUsersInfoModal,
            DisciplineInfoModal,
        },
        data() {
            return {
                interval: null,
                loading: true
            }
        },
        computed: {
            ...mapState('commission', ['candidateListData']),
        },

        methods: {
          decryptCheckRole,
            ...mapActions('commission', ['GET_CANDIDATE_LIST', 'GET_RESUME_SCORE', 'POST_KKK_USER_VOTE', 'GET_SCORE_USERS', 'GET_SCORE_BY_USERID', 'POST_ANOMYMOUS_VOTE_PERMISSION']),
            ...mapMutations('commission', ['SET_KKK_INTERVIEW_ID', 'SET_CANDIDATELISTDATAID']),
            ...mapMutations('userinfo', ['SET_USER_ID']),
            convertKkkInterviewTimestampToDate,
            getFio(user) {
                return `${user.lastname || ''} ${user.firstname || ''} ${user.middlename || ''}`
            },
            async openScoreModal(kkk_interview_id) {
                this.SET_KKK_INTERVIEW_ID(kkk_interview_id)
            },
            async setVote(kkk_interview_time_id, vote) {
                await this.POST_KKK_USER_VOTE({kkk_interview_time_id, vote});
                await this.GET_CANDIDATE_LIST(1);
            },
            async setScoreUsersId(usersId) {
                console.log('setScoreUsersId', usersId)
                await this.GET_SCORE_USERS(usersId)
            },
            goToUser(userId) {
                this.SET_USER_ID(userId)
                this.$router.push('/user')
            },
            async setPermission(kkk_interview_time_id) {
                await this.POST_ANOMYMOUS_VOTE_PERMISSION(kkk_interview_time_id);
                await this.GET_CANDIDATE_LIST(1);
            },
            async openScoreInfoModal(kkk_interview_id) {
                await this.GET_SCORE_BY_USERID(kkk_interview_id)
            },


        },
        async mounted() {
            await this.GET_CANDIDATE_LIST(1);
            this.loading = false;
            this.interval = setInterval(() => {
                this.GET_CANDIDATE_LIST(1)
            }, 5000)
        },
        beforeUnmount() {
            clearInterval(this.interval)
        },


    }
</script>

<style scoped>

</style>