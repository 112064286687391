<template>
  <!-- Score Modal -->
  <div class="modal fade" :id="pageId" tabindex="-1" aria-labelledby="DisciplineInfoModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-xl" >
      <div class="modal-content">
        <div class="modal-header" >
          <h5 class="modal-title" id="DisciplineInfoModalLabel">Дисциплина</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" v-if="getDisciplineInfo">

          <div class="m-2 mt-4" >
            <div class="row">
              <div class="col-md-11">
                <h4>Название</h4>
              </div>
              <div class="col-md-11">
                <h3>{{getDisciplineInfo.userSyllabus.disciplineVacancy.discipline.name}}</h3>
              </div>
              <div class="col-md-11">
                <h4>Описание</h4>
              </div>
              <div class="col-md-11">
                {{getDisciplineInfo.userSyllabus.disciplineVacancy.discipline.description}}
                <hr>
              </div>
              <div class="col-md-11">
                <h4>Знания</h4>
              </div>
              <div class="col-md-11">
                {{getDisciplineInfo.userSyllabus.disciplineVacancy.discipline.knowledge}}
                <hr>
              </div>
              <div class="col-md-11">
                <h4>Навыки</h4>
              </div>
              <div class="col-md-11">
                {{getDisciplineInfo.userSyllabus.disciplineVacancy.discipline.skills}}
                <hr>
              </div>
              <div class="col-md-11">
                <h4>Умения</h4>
              </div>
              <div class="col-md-11">
                {{getDisciplineInfo.userSyllabus.disciplineVacancy.discipline.abilities}}
                <hr>
              </div>
            </div>
          </div>

        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Закрыть
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Score Modal -->
</template>
<script>
import {mapState} from "vuex";

export default {
  name: 'DisciplineInfoModal',
  props: ['pageId'],
  computed: {
    ...mapState('commission', ['candidateListData','candidateListDataId']),
    getDisciplineInfo(){
      if(this.candidateListData.find(i => i.id == this.candidateListDataId)){
        return  this.candidateListData.find(i => i.id == this.candidateListDataId)
      }
      return null

    }
  },
}

</script>