<template>
    <!-- Score Modal -->
    <div class="modal fade" :id="pageId" tabindex="-1" aria-labelledby="selectCriteriaModalLabel"
         aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="selectCriteriaModalLabel"></h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">

                    <!-- table start -->
                    <!--              <div class="table-responsive-md mt-4">-->
                    <!--                <table class="table table-striped table-bordered">-->
                    <!--                  <thead>-->
                    <!--                  <tr>-->
                    <!--                    <th scope="col">№</th>-->
                    <!--                    <th scope="col">Название критерий</th>-->
                    <!--                    <th scope="col">Оценка</th>-->
                    <!--                  </tr>-->
                    <!--                  </thead>-->
                    <!--                  <tbody>-->

                    <!--                  <tr v-for="(item,index) in criterion" :key="index">-->
                    <!--                    <th scope="row">{{ index + 1 }}</th>-->
                    <!--                    <td>{{ item.name}}</td>-->
                    <!--                    <td>-->
                    <!--                      <input type="number" class="form-control" placeholder="От 0 до 5"-->

                    <!--                             @change="changeKkkUserCriterionScoreData(item,'k'+index, $event)">-->
                    <!--                    </td>-->

                    <!--                  </tr>-->
                    <!--                  </tbody>-->
                    <!--                </table>-->
                    <!--              </div>-->
                    <!-- table end -->

                    <!--              <div class="row">-->
                    <!--                <div class="col-md-6">-->
                    <!--                  <div class="mt-4" v-for="(item,index) in criterionData" :key="index">-->
                    <!--                    <label class="form-label">{{item.name}}</label>-->
                    <!--                  </div>-->

                    <!--                </div>-->
                    <!--                <div class="col-md-6">-->
                    <!--                  <div class="mt-4" v-for="(index) in criterionData.length" :key="index">-->
                    <!--                    <input type="text" class="form-control" placeholder="От 0 до 5"-->
                    <!--                           @change="changeKkkUserCriterionScoreData(candidateCriterionScoreInfos,'k'+index+1, $event)">-->
                    <!--                  </div>-->
                    <!--                  -->
                    <!--                </div>-->
                    <!--              </div>-->


                    <div class="col-md-12 mt-4">
                        <div class="form-row" v-if="criterionData[0]&&candidateCriterionScoreInfos[0]">

                            <div class="form-group row mt-4" v-for="(criterion, index) in criterionData"
                                 :key="'criterion'+index">
                                <label :for="'k'+(index+1)" class="col-md-6 col-form-label">
                                    {{ criterion.name}}</label>
                                <div class="col-md-6">
                                    <input :id="'k'+(index+1)" type="number" class="form-control"
                                           placeholder="От 0 до 5"
                                           :value="candidateCriterionScoreInfos[0]['k'+(index+1)]"
                                           @change="changeKkkUserCriterionScoreData(candidateCriterionScoreInfos,'k'+(index+1), $event)">
                                </div>
                            </div>

                            <div class="form-group row mt-4">
                                <label for="score_comment" class="col-md-6 col-form-label">Оставить комментарий</label>
                                <div class="col-md-6">
                                    <textarea id="score_comment" type="text" class="form-control"
                                              placeholder="Введите комментарий"
                                              :value="candidateCriterionScoreInfos[0]['score_comment']"
                                              @change="changeKkkUserCriterionScoreData(candidateCriterionScoreInfos,'score_comment', $event)">
                                    </textarea>
                                </div>
                            </div>


                        </div>
                    </div>


                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                            @click="submitUserCriterionScore">
                        Сохранить
                    </button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        Отменить
                    </button>

                </div>
            </div>
        </div>
    </div>
    <!-- End Score Modal -->
</template>
<script>
    import {mapActions, mapMutations, mapState} from "vuex";

    export default {
        name: 'ScoreModal',
        props: ['pageId'],
        data() {
            return {}
        },
        computed: {
            ...mapState('commission', ['criterionData', 'candidateCriterionScoreInfos'])
        },
        methods: {
            ...mapActions('commission', ['GET_CRITERION_DATA', 'POST_KKK_USER_CRITERION_SCORE', 'GET_CANDIDATE_LIST']),
            ...mapMutations('commission', ['UPDATE_CANDIDATE_CRITERION_SCORE_DATA']),
            changeKkkUserCriterionScoreData(item, property, e, val = 'value') {
                const value = e.target[val]
                console.log(item, 'item')
                this.UPDATE_CANDIDATE_CRITERION_SCORE_DATA({item, property, value})
            },
            async submitUserCriterionScore() {
                await this.POST_KKK_USER_CRITERION_SCORE();
                await this.GET_CANDIDATE_LIST(1);
            }
        },

        async mounted() {
            await this.GET_CRITERION_DATA();
        }
    }

</script>